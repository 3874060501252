/*-------- 2.5 Login/register --------*/
#login_bg, #register_bg {
  background: #ccc url(./../../images/entry-bgs/user_register.jpg) center center no-repeat fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  min-height: 100vh;
  width: 100%;
}
#register_bg.company {
  background: #ccc url(./../../images/entry-bgs/company_register.jpg) center center no-repeat fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  min-height: 100vh;
  width: 100%;
}

#login figure, 
#register figure {
  text-align: center;
  border-bottom: 1px solid #ededed;
  margin: -45px -60px 0 -60px;
  padding: 20px 60px 30px 60px;
}
@media (max-width: 767px) {
  #login figure, 
  #register figure {
    margin: -30px -30px 20px -30px !important;
    padding: 15px 60px 20px 60px;
  }
}

#login aside, 
#register aside {
  -webkit-box-shadow: 3px 0 30px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 3px 0 30px rgba(0, 0, 0, 0.2);
  box-shadow: 3px 0 30px rgba(0, 0, 0, 0.2);
  width: 430px;
  padding: 45px;
  position: absolute;
  left: 0;
  top: 0;
  overflow-y: auto;
  background-color: #fff;
  min-height: 100vh;
}
@media (max-width: 767px) {
  #login aside, 
  #register aside {
    width: 100%;
    padding: 30px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
}
#login aside form, 
#register aside form {
  display: block;
}
#login aside form .form-group input, 
#register aside form .form-group input {
  padding-left: 40px;
}
#login aside form .form-group i, 
#register aside form .form-group i {
  font-size: 21px;
  font-size: 1.3125rem;
  position: absolute;
  left: 12px;
  top: 10px;
  color: #ccc;
  width: 25px;
  height: 25px;
  display: block;
  font-weight: 400 !important;
}
#login aside .copy, #register aside .copy {
  text-align: center;
  position: absolute;
  height: 30px;
  left: 0;
  bottom: 30px;
  width: 100%;
  color: #999;
  font-weight: 500;
}

.invalid-feedback {
  font-size: 95%;
}

// Entry aside
.entry-aside {
    overflow: hidden;
    & > figure {
        text-align: center;
        border-bottom: 1px solid #ededed;
        margin: -45px -45px 20px !important;
        padding: 15px !important;
    }
    form {
        margin: 0;
    }
    .copy {
      margin-bottom: -20px;
    }
}
